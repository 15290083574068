<template>
  <CRow style="margin-top: 20px">
    <loading-overlay :active="isSubmit" :is-full-page="false" loader="bars"/>
    <CCol sm="12">
      <CRow>
        <CCol sm="12">
          <modalErrorList ></modalErrorList>
          <div class="position-icon">
            <img size="sm" width="30px" height="30px" 
              src="/img/iconos/GIF_AYUDA.gif"
              @click="ModalHelp=true"
            >
          </div>
          <CTabs variant="tabs" :active-tab="$store.state.planificacionestiba.newUnit" @update:activeTab="handleTab">
            <CTab :title=" $t('label.document')" class="form-especial">
              <CCard bodyWrapper>
                <CCollapse :show="!$store.state.planificacionestiba.auxList">
                  <CCollapse :show="!collapsePreliminar">
                    
                    <CRow alignVertical="center">
                      <CCol sm="5">
                        <CSelect
                          :label="$t('label.documentType')"
                          :horizontal="{ label: 'col-sm-6', input: 'col-sm-6'}"
                          addLabelClasses="required text-right"
                          :is-valid="hasError($v.formArchivos.TpEdiId)"
                          :placeholder="`${$t('label.select')}`"
                          :disabled="isTypesSelectEmpty"
                          :options="typesOptions"
                          :value.sync="$v.formArchivos.TpEdiId.$model"
                        />
                        <CElementCover
                          :opacity="1"
                          v-show="isLoadingTypes"
                        >
                          <label class="d-inline">{{$t('label.load')}}... </label>
                          <CSpinner />
                        </CElementCover>
                      </CCol>
                      <CCol sm="5"> <!-- :addLabelClasses="{ 'text-right required': !isTransacSelectEmpty ? true:null }"-->
                        <CSelect
                          :label="$t('label.transacType')"
                          :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                          addLabelClasses="text-right required"
                          :is-valid="hasError($v.formArchivos.TpEdiTransacId)"
                          :placeholder="`${$t('label.select')}`"
                          :disabled="isTransacSelectEmpty"
                          :options="transactionsOptions"
                          :value.sync="$v.formArchivos.TpEdiTransacId.$model"
                        />
                        <CElementCover
                          :opacity="1"
                          v-show="isLoadingTransac"
                        >
                          <label class="d-inline">{{$t('label.load')}}... </label>
                          <CSpinner />
                        </CElementCover>
                      </CCol>
                      <CCol
                        sm="2"
                        class="d-flex align-items-start justify-content-end"
                      >
                          <CButton
                            v-if="downloadDocument !== ''"
                            color="watch" 
                            class="mr-1"
                            v-c-tooltip="{
                              content:  $t('label.download_auxiliar_list_instructions'),
                              placement: 'top-end',
                            }"
                            target="_blank"
                            :href="this.$store.getters['connection/getBase']+'download/InstructivoListasAuxiliares.pdf'"
                          >
                            <img class="img-fluid rounded iconAuxiliarList" height="20px" width="20px" src="/img/iconos/Tab-InstructiveBlanco.svg" alt="Card image cap">
                          </CButton>
                          <CButton
                            v-if="downloadDocument !== ''"
                            color="info" 
                            class="mr-1"
                            v-c-tooltip="{
                              content:  $t('label.download_file_structure'),
                              placement: 'top-end',
                            }"
                            target="_blank"
                            :href="downloadDocument"
                          >
                            <CIcon name="cil-document"/>
                          </CButton>
                          <CButton
                            color="add" class="mr-1"
                            v-c-tooltip="{
                              content:  $t('label.add'),
                              placement: 'top-end',
                            }"
                            :disabled="isSubmitInvalid"
                            @click.stop="submitElectronicFiles"
                          >
                            <div v-if="!isSubmit">
                              <CIcon name="checkAlt" />
                            </div>
                            <div v-if="isSubmit">
                              <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>
                          </CButton>
                      </CCol>
                      <CCol sm="12" class="mt-3 mb-1">
                        <vue-dropzone
                          :aria-disabled="idIndentification"
                          ref="myVueDropzone"
                          id="dropzone"
                          :options="dropZoneOptions"
                          :useCustomSlot="true"
                          v-on:vdropzone-files-added="sendingEvent"
                          v-on:vdropzone-removed-file="deleteFile"
                          v-on:vdropzone-error="DropzoneError"
                        >
                          <div class="dropzone-custom-content">
                            <h3 class="dropzone-custom-title">
                              <CIcon name="cil-cloud-upload" /> ¡{{$t('label.dragAndDropToUploadContent')}}!
                            </h3>
                            <div class="subtitle">
                              ...{{$t('label.orClickToSelectFileFromYourComputer')}}
                            </div>
                          </div>
                        </vue-dropzone>
                      </CCol>
                      <CCollapse :show="isSubmit" class="upload-progress">
                        <CCol sm="12" class="mb-3">
                          <CProgress class="upload-progress">
                            <CProgressBar
                              color="success"
                              variant="striped"
                              animated
                              :value="uploadPercent"
                            >
                              {{ uploadPercent != 100 ? `${uploadPercent}%` : $t('label.readingFile') }}
                            </CProgressBar>
                          </CProgress>
                        </CCol>
                      </CCollapse>
                      <CCol sm="12">
                        <dataTableExtended
                          class="align-center-row-datatable"
                          size="sm"
                          :fields="fields"
                          :items="formatedItems"
                          :items-per-page="5"
                          column-filter
                          pagination
                          sorter
                          :table-filter="tableText.tableFilterText"
                          :items-per-page-select="tableText.itemsPerPageText"
                          :noItemsView="tableText.noItemsViewText"
                          :loading="isLoading"
                        >
                          <template #loading>
                            <loading/>
                          </template>
                          <template #Details="{ item }">
                            <td class="center-cell-vertical">
                                <CButton
                                  v-if="item.TpEdiId == '74423312-1450-4B16-B837-D5620CDCDD1D' || item.TpEdiId == 'AE4FB2C8-8471-43C8-9DE0-6394173F0330'"
                                  color="plan"
                                  size="sm"
                                  class="mr-1"
                                  :disabled="isSubmit || isDelete"
                                  v-c-tooltip="{
                                    content: $t('label.plans'),
                                    placement: 'top-start',
                                  }"
                                  @click.stop="toggleDetails(item, 1)"
                                >
                                  <CIcon name="blueprint" />
                                </CButton>
                                <CButton
                                  v-if="item.TpEdiId == '74423312-1450-4B16-B837-D5620CDCDD1D' || item.TpEdiId == 'F46BD5CA-3A58-4C15-8C19-A611A426B7A2'"
                                  color="watch"
                                  size="sm"
                                  class="mr-1"
                                  :disabled="isSubmit || isDelete"
                                  v-c-tooltip="{
                                    content: $t('label.details'),
                                    placement: 'top-start',
                                  }"
                                  @click.stop="toggleDetails(item)"
                                >
                                  <CIcon name="cil-list-rich" />
                                </CButton>
                                <CButton v-if="item.EdiFileRoute"
                                  color="excel"
                                  size="sm"
                                  class="mr-1"
                                  :disabled="isSubmit || isDelete"
                                  v-c-tooltip="{
                                    content: `${$t('label.download')} ${$t('label.file')}`,
                                    placement: 'top-start',
                                  }"
                                  :href="item.Route"
                                >
                                  <CIcon name="cil-cloud-download"/>
                                </CButton>
                                <CButton
                                  color="wipe"
                                  size="sm"
                                  v-c-tooltip="{
                                    content: `${$t('label.removeDocument')}`,
                                    placement: 'top-start',
                                  }"
                                  :disabled="isSubmit || isDelete"
                                  @click.stop="deleteConfirmation(item)"
                                >
                                  <CIcon name="cil-trash" v-if="!isDelete"/>
                                </CButton>
                            </td>
                          </template>
                          <template #RowNumber="{ item }">
                            <td class="center-cell-vertical text-center">
                              {{ item.RowNumber }}
                            </td>
                          </template>
                          <template #EdiFileName="{ item }">
                            <td class="center-cell-vertical text-left">
                              {{ item.EdiFileName }}
                            </td>
                          </template>
                          <template #TpEdiName="{ item }">
                            <td class="center-cell-vertical text-center">
                              {{ item.TpEdiName }}
                            </td>
                          </template>
                          <template #TpEdiTransacName="{ item }">
                            <td class="center-cell-vertical text-center">
                              {{ item.TpEdiTransacName }}
                              <!-- Descarga -->
                              <span v-if="item.TpEdiTransacId == '86648E9F-D6C9-4924-940A-B99AFB241468'">⬇️</span>
                              <!-- Carga -->
                              <span v-if="item.TpEdiTransacId == '0BDB045B-F7CF-4782-8ACF-AC04A1B342CF'">⬆️</span>
                            </td>
                          </template>
                          <template #DeparturePort="{ item }">
                            <td class="center-cell-vertical text-center">
                              {{ item.DeparturePort }}
                            </td>
                          </template>
                          <template #NextPort="{ item }">
                            <td class="center-cell-vertical text-center">
                              {{ item.NextPort }}
                            </td>
                          </template>
                          <template #TransaLogin="{ item }">
                            <td class="center-cell-vertical text-center">
                              {{ item.TransaLogin }}
                            </td>
                          </template>
                          <template #FormatedDate="{ item }">
                            <td class="center-cell-vertical text-center">
                              {{ item.FormatedDate }}
                            </td>
                          </template>
                          <template #Status="{ item }">
                            <td class="center-cell-vertical text-center">
                              <CBadge>
                                {{ item.Status }}
                              </CBadge>
                            </td>
                          </template>
                        </dataTableExtended>
                      </CCol>
                    </CRow>
                  </CCollapse>
                  <CCollapse :show="collapsePreliminar">
                    <reviewpreliminarlist />
                  </CCollapse>
                </CCollapse>
                <CCollapse :show="$store.state.planificacionestiba.auxList">
                  <auxlist />
                </CCollapse>
              </CCard>
            </CTab>
            <CTab :style="verificarTabs" :title=" $t('label.PreliminaryDischargeFile')">
              <preliminarDischargelist :value="newUnit" @child-refresh="newUnit=false"  />
            </CTab>
            <CTab :title=" $t('label.PreliminaryShipmentFile')">
              <preliminarLoadlist :value="newUnit" @child-refresh="newUnit=false"  />
            </CTab>
            <CTab :title=" $t('label.listings')">
              <comparacionListados :value="newUnit" @child-refresh="newUnit=false"  />
            </CTab>
          </CTabs>
        </CCol>
      </CRow>
      <CRow>
      </CRow>
    </CCol>
    <ModalHelpAuxiliaryFiles
      :modal.sync="ModalHelp"
      @Close="ModalHelp=false"
    />
  </CRow>
</template>
<script>
import { mapState } from "vuex";
import { DateFormater } from '@/_helpers/funciones';
import UpperCase from "@/_validations/uppercase-directive";
import { tableTextTranslatedHelpers, alertPropertiesHelpers } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ArchivosValidations, { ArchivosAllRequiredValidations } 
from "@/_validations/planificacion-estiba/archivosValidations";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import preliminarDischargelist from './preliminar/preliminarDischarge-list';
import auxlist from './preliminar/auxilar-list';
import comparacionListados from './comparacion/comparacionListados';
import modalErrorList from './modal-error-list';
import preliminarLoadlist from './preliminar/preliminarLoad-list';
import reviewpreliminarlist from './preliminar/review-preliminar-list';
import ModalHelpAuxiliaryFiles from './modal-help-auxiliary-files';

//data
function data() {
  return {
    isLoading: false,
    isLoadingTypes: false,
    isLoadingTransac: false,
    isSubmit: false,
    isDelete: false,
    ModalHelp: false,
    items: [],
    formArchivos: {
      TpEdiId: "",
      TpEdiTransacId: "",
      RouteFile: "",
      Format: ""
    },
    docFile: null,
    selectTransactions: [],
    selectDocType: [],
    uploadPercent: 0,
    responsePercent: 0,
  };
}

function fields(){
  return [
    { 
      key: 'Details', 
      label: '', 
      _style: 'min-width:170px; width:1%;', 
      sorter: false, 
      filter: false,
    },
    { 
      key: 'RowNumber', 
      label: '#',
      _style: 'width:2%; text-align:center;', filter: false
    },
    { key: 'EdiFileName', label:this.$t('label.filename'), _style:'width:15%;text-align:center;'},
    { key: 'TpEdiName', label:this.$t('label.type'), _style:'width:7%; text-align:center;'},
    { key: 'TpEdiTransacName', label:this.$t('label.transaction'), _style:'width:15%; text-align:center;'},
    { key: 'DeparturePort', label:'POL', _style:'min-width:100px; text-align:center;'},
    { key: 'NextPort', label:'POD', _style:'min-width:100px; text-align:center;'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:8%; text-align:center;'},
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:10%; text-align:center;'},
    { key: 'Status', label: this.$t('label.status'), _style:'width:15%; text-align:center;'},
  ];
}

//methods
function handleTab(tab) {
  this.$store.state.planificacionestiba.newUnit = tab;
}
function getDocumentList() {
  this.isLoading = true;

  let StowagePlanningId = this.planificacionId;

  this.$http.ejecutar("GET", "StowagePlanningEdiFile-list", {
      StowagePlanningId: StowagePlanningId,
      Filter: 'ALL'
  }).then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function getTransactionTypes(TypeId) {
  this.isLoadingTransac = true;

  this.$http.ejecutar("GET", "TpEdiTransac-list", { TpEdiId: TypeId, Filter: 'ACTIVO' })
  .then(response => {
    this.selectTransactions = response.data.data || [];
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingTransac = false;
  });
}

function loadData() {
  this.isLoading = true;
  this.isLoadingTypes = true;
  this.$store.state.planificacionestiba.apiStateForm = 1;

  let StowagePlanningId = this.planificacionId;
  let peticiones = [
    this.$http.ejecutar("GET", "StowagePlanningEdiFile-list", { StowagePlanningId: StowagePlanningId, Filter: 'ALL' }),
    this.$http.ejecutar("GET", "TpEdi-list", { Filter: 'ACTIVO' })
  ];

  Promise.all(peticiones)
  .then((responses) => {
    this.items = responses[0].data.data;
    this.selectDocType = responses[1].data.data;
    this.selectTransactions = [];
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
    this.isLoadingTypes = false;
    this.$store.state.planificacionestiba.apiStateForm = 0;
  });
}
function resetform() {
  this.formArchivos.TpEdiId = "";
  this.formArchivos.TpEdiTransacId = "";
  this.formArchivos.RouteFile = "";
  this.formArchivos.Format = "";
  this.selectTransactions = [];
  this.$refs.myVueDropzone.removeAllFiles();
  this.docFile = null;
  this.uploadPercent = 0;
  this.responsePercent = 0;
  this.$nextTick(() => {
    this.$v.formArchivos.$reset();
  });
}
function toggleDetails(item, type = 0) {
  this.resetform();
  this.$store.state.planificacionestiba.TpEdiId = item.TpEdiId;
  this.$store.state.planificacionestiba.EdiFileId = item.EdiFileId;
  this.$store.state.planificacionestiba.EdiFileInfo = item;
  if((item.TpEdiId == '74423312-1450-4B16-B837-D5620CDCDD1D' || item.TpEdiId == 'AE4FB2C8-8471-43C8-9DE0-6394173F0330') && type == 1){
    this.$store.commit('planificacionestiba/menuVisibility', {check:true,type:1});
  }else{
    this.$store.commit('planificacionestiba/menuVisibility', {check:true,type:2});
  }
}
async function submitElectronicFiles() {
  this.isSubmit = true;
  let formData = new FormData();
  formData.append('StowagePlanningId', this.planificacionId);
  formData.append('TpEdiId', this.formArchivos.TpEdiId);
  formData.append('TpEdiTransacId', this.formArchivos.TpEdiTransacId);
  if(this.downloadDocument === ''){
    formData.append('File', this.docFile);
    this.$http.post("edi", formData, {
        baseURL: `${this.$store.getters["connection/getApiBase"]}edi/`,
        onUploadProgress: function( progressEvent ) {
          this.uploadPercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
        }.bind(this)
    }).then((response) => {
      if(response.data.data[0].FgSave === 1){
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: response.data.data[0].Response,
          type: "success"
        });
        this.getDocumentList();
        this.resetform();
      }else{
        if(response.data.data[0].ResponseJson !== null){
          if(response.data.data[0].ResponseJson.length !== 0){
            let ResponseJson = response.data.data[0].ResponseJson;
            let ErrorJson = [];
            ResponseJson.map(async(item) => {
              let Row = item.Nro
              if(item.ErrorJson.length !== 0){
                item.ErrorJson.map(async(item2) => {
                  ErrorJson.push({
                    Row : Row,
                    ColumnName: item2.ColumnName,
                    ColumnValue: item2.ColumnValue,
                    ErrorMessage: item2.ErrorMessage
                  })
                })
              }
            })
            this.$store.state.planificacionestiba.alertInformation = {
              messajeTitle:response.data.data[0].Response,
              ErrorJson:ErrorJson
            }
            this.$store.state.planificacionestiba.modalAlertList = true;
          }else{
            this.$notify({
              group: 'container',
              title: '¡Advertencia!',
              text: response.data.data[0].Response,
              type: "warn"
            });
          }
        }else{
          this.$notify({
            group: 'container',
            title: '¡Advertencia!',
            text: response.data.data[0].Response,
            type: "warn"
          });
        }
        
      }
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      //this.$refs.myVueDropzone.setOption("addRemoveLinks",true);
      this.isSubmit = false;
      this.uploadPercent = 0;
      this.responsePercent = 0;
    });
  }else{
    formData.append('files', this.docFile);
    this.$http.post("readExcel", formData, {
        baseURL: `${this.$store.getters["connection/getApiBase"]}`,
        onUploadProgress: function( progressEvent ) {
          this.uploadPercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
        }.bind(this)
    }).then((response) => {
      if(response.data.data[0].FgSave === 1){
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: response.data.data[0].Response,
          type: "success"
        });
        this.getDocumentList();
        this.resetform();
      }else{
        if(response.data.data[0].ResponseJson !== null){
          if(response.data.data[0].ResponseJson.length !== 0){
            let ResponseJson = response.data.data[0].ResponseJson;
            let ErrorJson = [];
            ResponseJson.map(async(item) => {
              let Row = item.Nro
              if(item.ErrorJson.length !== 0){

                item.ErrorJson.map(async(item2) => {
                  ErrorJson.push({
                    Row : Row,
                    ColumnName: item2.ColumnName,
                    ColumnValue: item2.ColumnValue,
                    ErrorMessage: item2.ErrorMessage
                  })
                })
                
              }
            })
            this.$store.state.planificacionestiba.alertInformation = {
              messajeTitle:response.data.data[0].Response,
              ErrorJson:ErrorJson
            }
            this.$store.state.planificacionestiba.modalAlertList = true;
          }else{
            this.$notify({
              group: 'container',
              title: '¡Advertencia!',
              text: response.data.data[0].Response,
              type: "warn"
            });
          }
        }else{
          this.$notify({
            group: 'container',
            title: '¡Advertencia!',
            text: response.data.data[0].Response,
            type: "warn"
          });
        }
        
      }
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      //this.$refs.myVueDropzone.setOption("addRemoveLinks",true);
      this.isSubmit = false;
      this.uploadPercent = 0;
      this.responsePercent = 0;
    });
  }
}
function sendingEvent(files) {
  if (["", null, undefined].includes(this.planificacionId)) {
    this.$refs.myVueDropzone.removeFile(files);
  }

  let ext = "";
  if(files[0].name)
    ext = files[0].name.substring(files[0].name.lastIndexOf('.')+1, files[0].name.length);
  // ['pdf','doc','docx','xlsx'].includes(ext.toLowerCase())
  let format = this.formArchivos.Format === '.xls' ? '.xlsx' : ''
  if(`.${ext.toLowerCase()}` == this.formArchivos.Format ){
    this.docFile = files[0];
  } else if(format !== '' && `.${ext.toLowerCase()}` == format){
    this.docFile = files[0];
  }else{
    this.$refs.myVueDropzone.removeAllFiles();
    let msg = this.formArchivos.Format == ''
      ? `${this.$t('label.mustSelectDocType')}`
      : `${this.$t('label.docTypeInvalid')}`
    this.$swal.fire({
      icon: 'error',
      title: '',
      text: msg
    })
  }
}
function deleteFile(file) {
  this.docFile = null;
}
function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}
function hasError(field){
  return field.$dirty ? !field.$error : null;
}
function deleteConfirmation(item) {
  this.$swal.fire(alertPropertiesHelpers(this,{
    text: `${this.$t('label.deleteFile')}`,
  })).then((result) => {
    if (result.isConfirmed) {
      this.desactivarEdiFile(item);
    }
  });
}
function desactivarEdiFile(item) {
   this.isDelete = true;

  let EdiJson = {
      EdiFileId: item.EdiFileId
  };

  this.$http.put('EdiFile-Deactivate', EdiJson, { root: 'EdiJson'} )
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response.data.data[0].Response,
      type: "success"
    });
    this.getDocumentList();
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isDelete = false;
  });
}

//computed
function verificarTabs() {
  return this.ConditionPlanningId=='36EAD265-E8F4-EC11-A87E-000D3A0DEDC7' ? 'display: none;' : 'text-transform: uppercase;';
}
function downloadDocument(){
  if(this.selectTransactions.length !== 0){
    if(this.selectItems){
      const Index = this.selectTransactions.map(function(e) {
          return e.TpEdiTransacId; 
      }).indexOf(this.formArchivos.TpEdiTransacId);
      if(Index !== -1){
        const router = this.selectTransactions[Index].Route;
        if(router !== null && router !== ''){
          return `${this.$store.getters["connection/getBase"]}${router}`;
        }else{
          return '';
        }
      }else{
        return '';
      }
    }else{
      return '';
    }
  }else{
    return '';
  }
}
function selectItems(){
  return this.formArchivos.TpEdiTransacId !== null && this.formArchivos.TpEdiTransacId !== '' 
    && this.formArchivos.TpEdiId !== null && this.formArchivos.TpEdiId !== '';
}
function idIndentification() {
  return ["", null, undefined].includes(this.planificacionId);
}
function formatedItems() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);

  return this.items.map((item) => Object.assign({}, item, {
    Route: item.EdiFileRoute ? `${this.$store.getters["connection/getBase"]}${item.EdiFileRoute}`:'',
    RowNumber: Number.parseFloat(item.Nro),
    Status: item[`Status${_lang}`],
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Errors!=0?'Error-Row': item.Alerts!=0?'table-warning':'table-success',
  }));
}
function dropZoneOptions() {
  const token = this.user.token;
  return {
      url: `${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      maxFiles: 1,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded(files) {
        this.removeAllFiles();
        this.addFile(files);
      },
      acceptedFiles: ".pdf, .doc, .docx, .xlsx, .edi",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
}
function isTypesSelectEmpty() {
  return this.selectDocType.length == 0;
}
function typesOptions() {
  return this.selectDocType.map((item) => Object.assign({}, item, {
    label: item.TpEdiName,
    value: item.TpEdiId
  }));
}
function isTransacSelectEmpty() {
  return this.selectTransactions.length == 0;
}
function transactionsOptions() {
  return this.selectTransactions.map((item) => Object.assign({}, item, {
    label: item.TpEdiTransacName,
    value: item.TpEdiTransacId
  }));
}
function docTypeId() {
  return this.formArchivos.TpEdiId;
}
function isSubmitInvalid() {
  return this.$v.$invalid || this.isSubmit;
}
function tableText() {
  return tableTextTranslatedHelpers(this);
}

export default {
  name: "archivos-electronicos",
  data,
  props: {

  },
  mixins: [GeneralMixin],
  directives: UpperCase,
  validations() {
    if(this.isTransacSelectEmpty)
      return ArchivosValidations();
    else
      return ArchivosAllRequiredValidations();
  },
  components: {
    vueDropzone: vue2Dropzone,
    modalErrorList,
    auxlist,
    comparacionListados,
    preliminarDischargelist,
    preliminarLoadlist,
    reviewpreliminarlist,
    ModalHelpAuxiliaryFiles,
  },
  methods: {
    sendingEvent,
    toggleDetails,
    loadData,
    getTransactionTypes,
    resetform,
    submitElectronicFiles,
    hasError,
    deleteFile,
    DropzoneError,
    getDocumentList,
    desactivarEdiFile,
    deleteConfirmation,
    handleTab
  },
  computed: {
    verificarTabs,
    idIndentification,
    dropZoneOptions,
    formatedItems,
    isTypesSelectEmpty,
    typesOptions,
    isTransacSelectEmpty,
    transactionsOptions,
    isSubmitInvalid,
    selectItems,
    downloadDocument,
    docTypeId,
    tableText,
    fields,
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
      collapsePlanificacion: (state) => state.planificacionestiba.collapsePlanificacion,
      isHidden: (state) => state.planificacionestiba.isHidden,
      tabIndex: (state) => state.planificacionestiba.tabIndex,
      user: (state) => state.auth.user,
      newUnit: (state) => state.planificacionestiba.newUnit,
      collapsePreliminar: (state) => state.planificacionestiba.collapsePreliminar,
      ConditionPlanningId: state => state.planificacionestiba.ConditionPlanningId,
    }),
  },
  watch: {
    tabIndex: function(newIndex){
      if(newIndex === 1&&!["", null, undefined].includes(this.planificacionId)&&this.collapsePlanificacion){
        this.loadData();
      } else{
        this.resetform();
      }
    },
    collapsePlanificacion: function(collapse) {
      if(!collapse) {
        this.$store.state.planificacionestiba.isHidden = false;
        this.resetform();
      }
    },
    isHidden: function(newValue) {
      if(!newValue) {
        this.$store.state.planificacionestiba.TpEdiId = '';
        this.$store.state.planificacionestiba.EdiFileId = 0;
        this.$store.state.planificacionestiba.EdiFileInfo = null;
      }
    },
    docTypeId: function(newId) {
      if(newId){
        this.getTransactionTypes(newId);

        let tpEdi = this.selectDocType.find((item) => item.TpEdiId == newId);
        this.formArchivos.Format = tpEdi ? tpEdi.Format : '';
      }
    },
    newUnit: function(val) {
      if (val==0 && !["", null, undefined].includes(this.planificacionId)) {
        this.loadData();
      }
    },
  },
};
</script>
<style scoped>
.position-icon{
  cursor: pointer;
  position: absolute;
  top: 1%;
  left: 95%;
  z-index: 1;
}
.center-cell-vertical {
  text-align: center;
  vertical-align: middle !important;
}
.upload-progress {
  width: 100%;
}
.iconAuxiliarList {
  filter: invert(0) sepia(0) saturate(1) hue-rotate(0deg) brightness(1.2);
}
</style>
